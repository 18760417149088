<template>
    <el-dialog
        title="联系方式"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="800px">
        <Table :table="table" :total="total" :page.sync="concatInfo.page" :size.sync="concatInfo.size" @pagination="getConcatInfo"></Table>
        <span slot="footer" class="dialog-footer">
        </span>
    </el-dialog>

</template>
<script>
import Table from '@/components/table/table';
  export default {
    components:{ Table },
    model:{
        prop:'show',
        event:'contactDetailsDialog'
    },
    props:{
        show: {
            type:Boolean,
            default:false
        },
        userList:{
            type:Object,
            default: ()=> {
                return {}
            }
        },
        toSeeConcat:{
            type:Object,
            default: ()=> {
                return {}
            }
        }
    },
    data() {
      return {
        dialogVisible: this.show, //弹框的显示
        total: this.userList.total || 0,
        concatInfo:{
            page: this.toSeeConcat.page || 1,
            size: this.toSeeConcat.size || 10
        },
        table: {
            data: this.userList.rows, //表格数据展示
            header: [
                {
                    type: 'index',
                    label: '序号',
                    width: '55',
                    ifShow: true,
                },
                {
                    prop: 'name',
                    label: '用户名',
                    width: '180',
                    tooltip: true,
                    ifShow: true,
                },
                {
                    prop: 'organizationName',
                    label: '所属组织',
                    tooltip: true,
                    ifShow: true,
                },
                {
                    prop: 'phone',
                    label: '手机号码',
                    tooltip: true,
                    ifShow: true,
                },
                {
                    prop: 'roleName',
                    label: '用户角色',
                    tooltip: true,
                    ifShow: true,
                },
            ]}
        }
    },
    methods:{
        getConcatInfo(){
            this.$emit('getUserList' ,this.concatInfo)
        }
    },
    watch:{
        show:{
            handler(newVal, oldVal) {
                this.dialogVisible = newVal;
            },
            deep: true
        },
        dialogVisible:{
            handler(newVal, oldVal) {
                this.$emit('contactDetailsDialog',newVal);
            },
            deep: true
        },
        userList:{
            handler(newVal, oldVal) {
                this.table.data = newVal.rows; 
                this.total = newVal.total;
            },
            deep: true
        },
        toSeeConcat: {
            handler(newVal, oldVal) {
                this.concatInfo = newVal;
            },
            deep: true,
        }
    }
  };
</script>