import request from '@/utils/request';
//获取电量过低统计列表
export function lowPowerDevice(data) {
    return request({
        url: '/report/lowPowerDevice',//请求路径
        method: 'post',
        data
    });
}

//获取设备类型列表
export function getDeviceType(data) {
    return request({
        url: '/device/type/list',//请求路径
        method: 'post',
        data
    });
}
//低电量告警 列表导出
export function lowPowerDeviceExport(data) {
    return request({
        url: '/report/lowPowerDeviceExport',//请求路径
        method: 'post',
        data,
        responseType: 'blob'
    });
}

